body,
html {
  font-family: 'Kanit', sans-serif;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(./fonts/Kanit-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 900;
  src: local('Kanit'), url(./fonts/Kanit-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 900;
  src: local('Kanit'), url(./fonts/Kanit-Regular.ttf) format('truetype');
}